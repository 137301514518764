import React from 'react';
import { ButtonLight } from 'components/atoms/buttons';
import Logo from 'components/atoms/icons/logo';
import styled from 'styled-components';
import media from 'lib/styles/media';
import theme from 'lib/styles/theme';
import WithFooter from 'components/layouts/with-footer';

const { colors } = theme;

export const PageWrap = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: ${colors.jackpocketBlue};
  > svg {
    max-width: 200px;
    margin-bottom: 100px;
  }
`;

export const PageContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0 50px;
  align-items: center;
  padding: 20px;

  h1,
  p,
  p > a {
    color: ${colors.white};
  }

  ${media.medium`
  grid-template-columns: 500px 400px;
  `}
`;

export const Glyph = styled.div`
  text-align: center;
  order: -1;
  > img {
    max-width: 200px;
  }
  ${media.medium`
order: 2;
  > img {
    max-width: 350px;
  }
  `}
`;

const CopyBlock = styled.div`
  text-align: center;
  > a {
    width: fit-content;
  }
`;

export default function NotFoundPage() {
  return (
    <WithFooter>
      <PageWrap>
        <Logo isLight />
        <PageContent>
          <CopyBlock>
            <h1>Whoops, we can&apos;t find that page.</h1>
            <p>
              Maybe you have a typo in the address? You can go home or try
              checking our{' '}
              <a
                href="https://support.jackpocket.com"
                target="_blank"
                rel="noreferrer"
              >
                FAQ
              </a>
              .
            </p>
            <ButtonLight as="a" href="/" rel="noreferrer">
              GO HOME
            </ButtonLight>
          </CopyBlock>
          <Glyph>
            <img src="/404_glyph.svg" alt="404" />
          </Glyph>
        </PageContent>
      </PageWrap>
    </WithFooter>
  );
}
